import "./slider";
import "./service";
import "./contact";

// header
const header = document.querySelector(".header");
const headerPc = document.querySelector(".pc-header");
const headerPcRecruit = document.querySelector(".pc-recruit-header");
const headerBtn = document.querySelector(".sp-header__btn");
const headerContent = document.querySelector(".sp-header__content");
const headerContentBg = document.querySelector(".sp-header__content-bg");
const headerContentClose = document.querySelector(".sp-header__content-close");
const body = document.querySelector("body");

function toggleHeader() {
    headerContent.classList.toggle("sp-header__content--active");
    body.classList.toggle("fixed");
}

function scrollHeader() {
    if (window.scrollY > 0) {
        headerPc.classList.add("pc-header--scroll");
    } else {
        headerPc.classList.remove("pc-header--scroll");
    }
}

function scrollRecruitHeader() {
    if (window.scrollY > 0) {
        headerPcRecruit.classList.add("pc-recruit-header--scroll");
    } else {
        headerPcRecruit.classList.remove("pc-recruit-header--scroll");
    }
}

if (header) {
    headerBtn.addEventListener("click", toggleHeader);
    headerContentBg.addEventListener("click", toggleHeader);
    headerContentClose.addEventListener("click", toggleHeader);

    window.addEventListener("scroll", scrollHeader);
    window.addEventListener("DOMContentLoaded", scrollHeader);

    window.addEventListener("resize", function () {
        if (headerContent.classList.contains("sp-header__content--active") && window.innerWidth >= 1024) {
            toggleHeader();
        }
    });
}

// recruit-headerというクラスで上記のheaderと同じような処理を行う
const recruitHeader = document.querySelector(".recruit-header");
const recruitHeaderBtn = document.querySelector(".sp-recruit-header__btn");
const recruitHeaderContent = document.querySelector(".sp-recruit-header__content");
const recruitHeaderContentBg = document.querySelector(".sp-recruit-header__content-bg");
const recruitHeaderContentClose = document.querySelector(".sp-recruit-header__content-close");
const recruitHeaderContentLinks = document.querySelectorAll(".sp-recruit-header__content-link");


function toggleRecruitHeader() {
    recruitHeaderContent.classList.toggle("sp-recruit-header__content--active");
    body.classList.toggle("fixed");
}

if (recruitHeader) {
    recruitHeaderBtn.addEventListener("click", toggleRecruitHeader);
    recruitHeaderContentBg.addEventListener("click", toggleRecruitHeader);
    recruitHeaderContentClose.addEventListener("click", toggleRecruitHeader);
    recruitHeaderContentLinks.forEach((link) => {
        link.addEventListener("click", toggleRecruitHeader);
    });

    window.addEventListener("scroll", scrollRecruitHeader);
    window.addEventListener("DOMContentLoaded", scrollRecruitHeader);

    window.addEventListener("resize", function () {
        if (recruitHeaderContent.classList.contains("sp-recruit-header__content--active") && window.innerWidth >= 1024) {
            toggleRecruitHeader();
        }
    });
}


// footer
const footer = document.querySelector(".footer");
const floating = document.querySelector(".top-floating");
if (floating) {
    function scrollFloating() {
        if (footer.getBoundingClientRect().top < window.innerHeight) {
            floating.classList.add("top-floating--none");
        } else {
            floating.classList.remove("top-floating--none");
        }
    }
    window.addEventListener("scroll", scrollFloating);
}

// キャッシュ用にcssに対してクエリパラメータを付与
// const linkStylesheets = document.querySelectorAll("link[rel='stylesheet']");
// linkStylesheets.forEach((linkStylesheet) => {
//     const href = linkStylesheet.getAttribute("href");
//     if (href && href.match(/\.css$/)) {

//         function padZero(num) {
//             return num.toString().padStart(2, "0");
//         }

//         function getDateTimeString() {
//             const now = new Date();
//             const year = now.getFullYear();
//             const month = padZero(now.getMonth() + 1);
//             const day = padZero(now.getDate());
//             const hours = padZero(now.getHours());
//             const minutes = padZero(now.getMinutes());
//             const seconds = padZero(now.getSeconds());

//             return `${year}${month}${day}${hours}${minutes}${seconds}`;
//         }

//         const dateTimeString = getDateTimeString();
//         linkStylesheet.setAttribute("href", href + "?ver=" + dateTimeString);
//     }
// });



const recruitDescriptionBtnLinks = document.querySelectorAll('.recruit-description__btn-link');
const recruitDescriptionItems = document.querySelectorAll('.recruit-description__item');
const recruitDescriptionBtnItems = document.querySelectorAll('.recruit-description__btn-item');

function handleButtonClick(e, index) {
    e.preventDefault();
    recruitDescriptionBtnLinks.forEach((btnLink) => {
        btnLink.classList.remove('recruit-description__btn-link--active');
    });
    e.currentTarget.classList.toggle('recruit-description__btn-link--active');
    recruitDescriptionItems.forEach((item) => {
        item.classList.remove('recruit-description__item--active');
    });
    recruitDescriptionItems[index].classList.toggle('recruit-description__item--active');
    recruitDescriptionBtnItems.forEach((item) => {
        item.classList.remove('recruit-description__btn-item--active');
    });
    recruitDescriptionBtnItems[index].classList.toggle('recruit-description__btn-item--active');
}
if (recruitDescriptionBtnLinks) {
    recruitDescriptionBtnLinks.forEach((btnLink, index) => {
        btnLink.addEventListener('click', (e) => handleButtonClick(e, index));
    });
}

// aタグをクリックし#がついたリンクをクリックした時にスクロールする
const links = document.querySelectorAll('a[href^="#"]');
links.forEach((link) => {
    link.addEventListener('click', (e) => {
        e.preventDefault();
        const href = link.getAttribute('href');
        const target = document.querySelector(href);
        const offsetTop = target.getBoundingClientRect().top;
        window.scrollTo({
            top: offsetTop + window.pageYOffset,
            behavior: 'smooth'
        });
    });
});